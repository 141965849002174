<template>
  <div class="delete-fiscal-year">
    <SettingCard
      :title="$t('fiscal-year.unlock.title')"
      @onClick="openSelectModal"
      :disable="disable"
    />
    <select-items-modal
      v-if="isSelectModalOpen"
      :items-list="filesFilter"
      :selected-list="[]"
      :text="selectFileModalText"
      textBtnSave="Déverrouiller"
      select-type="single"
      @close="closeSelectModal"
      @saved="handleSaveModal"
    >
      <template #icon>
        <MeepIconCloud class="app-icon-color" />
      </template>
    </select-items-modal>
    <confirm-modal
      class="modal-fiscal-year"
      v-model="isConfirmModalOpen"
      :title="$t('fiscal-year.unlock.confirm-modal.title')"
      :description="descriptionConfirmModal"
      :question="$t('fiscal-year.unlock.confirm-modal.question')"
      cta-yes="Oui"
      cta-no="Non"
      @close="isConfirmModalOpen = false"
      @confirm="handleUnlock"
    />
    <LoadingModal v-model="isLoading" :text="$t('modal.text-loading')" />
    <AnnounceModal
      class="announce-fiscal-modal"
      v-model="isAnnounceModalOpen"
      :isNotiSuccess="isNotiSuccess"
      :description="descriptionAnnounce"
      @close="isAnnounceModalOpen = false"
    />
  </div>
</template>

<script>
import filesModel from "@/model/files";
import SettingCard from "@/components/SettingCard.vue";
import selectItemsModal from "@/components/modal/select-items";
import MeepIconCloud from "@/components/icons/MeepIconCloud.vue";
import ConfirmModal from "@/components/modal/ConfirmModal.vue";
import AnnounceModal from "@/components/modal/AnnounceModal.vue";
import LoadingModal from "@/components/modal/LoadingModal.vue";
import { mapGetters } from "vuex";

export default {
  name: "UnlockFiscalYear",
  components: {
    SettingCard,
    selectItemsModal,
    MeepIconCloud,
    ConfirmModal,
    AnnounceModal,
    LoadingModal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSelectModalOpen: false,
      isConfirmModalOpen: false,
      selectFileModalText: {
        header: this.$t("fiscal-year.unlock.modal.title"),
      },
      descriptionConfirmModal: "",
      selectedYearId: null,
      files: [],
      isLoading: false,
      isAnnounceModalOpen: false,
      isNotiSuccess: true,
    };
  },
  computed: {
    ...mapGetters(["organizationId"]),
    filesFilter() {
      return this.files.map((item) => ({ id: item, name: item }));
    },
    descriptionAnnounce() {
      return this.isNotiSuccess
        ? this.$t("fiscal-year.unlock.success")
        : this.$t("announce.error");
    },
  },
  methods: {
    async handleSaveModal(selectedList) {
      this.selectedYearId = selectedList[0].id;
      this.descriptionConfirmModal = `Vous êtes sur le point de déverrouiller l'année fiscale ${selectedList[0].name}, voulez-vous continuer ?`;
      this.isSelectModalOpen = false;
      this.isConfirmModalOpen = true;
    },
    async openSelectModal() {
      try {
        const response = await filesModel.getFiscalYears(this.organizationId);
        this.files = response.fiscalYears;
        this.isSelectModalOpen = true;
      } catch (error) {
        console.log(error);
      }
    },
    closeSelectModal(e) {
      if (e) {
        return;
      }
      this.isSelectModalOpen = false;
    },
    async handleUnlock() {
      this.isConfirmModalOpen = false;
      this.isLoading = true;
      try {
        await filesModel.unlockFiscalYear(
          this.selectedYearId,
          this.organizationId
        );
        this.isNotiSuccess = true;
      } catch {
        this.isNotiSuccess = false;
      }
      this.isLoading = false;
      this.isAnnounceModalOpen = true;
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/color";
.delete-fiscal-year {
  .app-icon-color {
    path {
      fill: transparent;
      stroke: $cl-yellow;
    }
  }
  .select-items-modal {
    .modal-body-title {
      p {
        font-size: 25px;
        color: #032575;
      }
    }
  }
}
</style>
