<template>
  <div class="delete-fiscal-year">
    <SettingCard
      :title="$t('fiscal-year.delete.title')"
      @onClick="openSelectModal"
      :disable="disable"
    />
    <select-items-modal
      v-if="isSelectModalOpen"
      :items-list="filesFilter"
      :selected-list="[]"
      :text="selectFileModalText"
      select-type="single"
      @close="closeSelectModal"
      @saved="handleSaveModal"
    >
      <template #icon>
        <MeepIconCloud class="app-icon-color" />
      </template>
    </select-items-modal>
    <confirm-modal
      class="modal-fiscal-year"
      v-model="isConfirmModalOpen"
      :title="$t('fiscal-year.delete.confirm-modal.title')"
      :description="descriptionConfirmModal"
      :question="$t('fiscal-year.delete.confirm-modal.question')"
      :cta-yes="$t('fiscal-year.delete.confirm-modal.yes')"
      :cta-no="$t('confirm-action-modal.no')"
      @close="isConfirmModalOpen = false"
      @confirm="handleDelete"
    />
    <LoadingModal v-model="isLoading" :text="$t('modal.text-loading')" />
    <AnnounceModal
      class="announce-success-modal"
      v-model="isAnnounceModalOpen"
      :isNotiSuccess="isSuccess"
      :description="$t('fiscal-year.delete-success')"
      @close="isAnnounceModalOpen = false"
    />
  </div>
</template>

<script>
import filesModel from "@/model/files";
import SettingCard from "@/components/SettingCard.vue";
import selectItemsModal from "@/components/modal/select-items";
import MeepIconCloud from "@/components/icons/MeepIconCloud.vue";
import ConfirmModal from "@/components/modal/ConfirmModal.vue";
import LoadingModal from "@/components/modal/LoadingModal.vue";
import AnnounceModal from "@/components/modal/AnnounceModal.vue";
import { mapGetters } from "vuex";
export default {
  name: "DeleteFiscalYear",
  components: {
    SettingCard,
    selectItemsModal,
    MeepIconCloud,
    ConfirmModal,
    LoadingModal,
    AnnounceModal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSelectModalOpen: false,
      isConfirmModalOpen: false,
      selectFileModalText: {
        header: this.$t("fiscal-year.delete.modal.title"),
      },
      descriptionConfirmModal: this.$t(
        "fiscal-year.delete.confirm-modal.description"
      ),
      selectedYear: null,
      years: [],
      isLoading: false,
      isAnnounceModalOpen: false,
      isSuccess: true,
    };
  },
  computed: {
    ...mapGetters(["organizationId"]),
    filesFilter() {
      return this.years.fiscalYears.map((item, index) => ({
        id: index,
        name: item,
      }));
    },
  },
  methods: {
    async handleSaveModal(selectedList) {
      this.selectedYear = selectedList[0].name;
      this.descriptionConfirmModal += `« ${selectedList[0].name} ». `;
      this.isSelectModalOpen = false;
      this.isConfirmModalOpen = true;
    },
    async openSelectModal() {
      try {
        this.years = await filesModel.getFiscalYears(this.organizationId);
        this.isSelectModalOpen = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.$emit("input", false);
      }
    },
    closeSelectModal(e) {
      if (e) {
        return;
      }
      this.isSelectModalOpen = false;
    },
    async handleDelete() {
      // call API delete
      this.isConfirmModalOpen = false;
      this.isLoading = true;
      try {
        await filesModel.removeFiscalYear(
          this.organizationId,
          this.selectedYear
        );
        this.isSuccess = true;
      } catch (err) {
        this.isSuccess = false;
      } finally {
        this.isLoading = false;
        this.isAnnounceModalOpen = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/color";
.delete-fiscal-year {
  .app-icon-color {
    path {
      fill: transparent;
      stroke: $cl-yellow;
    }
  }
  .select-items-modal {
    .modal-body-title {
      p {
        font-size: 25px;
        color: #032575;
      }
    }
  }
}
</style>
