<template>
  <md-dialog
    :md-active="value"
    @md-close="closeModal"
    @md-clicked-outside="closeModal"
    class="confirm-modal"
  >
    <div class="confirm-modal-header">
      <div
        :class="isGC ? 'app-logo-minified' : 'app-logo'"
        v-html="isGC ? logoMinified : logo"
      />
    </div>
    <div class="confirm-modal-body">
      <div class="confirm-modal-body-title">
        <div class="ellipse-78"></div>
      </div>
      <div class="confirm-modal-body-content">
        <p class="confirm-modal-body-context-loading">{{ text }}</p>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoadingModal",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isConfirmed: false,
    };
  },
  computed: {
    ...mapGetters(["logo", "logoMinified", "isGC", "isJEPA"]),
    styleClassIcon() {
      return {
        "app-icon": true,
        "app-icon--jepa": this.isJEPA,
      };
    },
  },
  methods: {
    closeModal() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";
@import "../../styles/color";
.confirm-modal {
  font-family: inherit;
  bottom: 50px;

  .md-dialog-container {
    min-width: 50%;
    padding: 0 0 toRem(45) toRem(27);
    box-shadow: none;
    border-radius: 27px;
    @include for-lg {
      min-height: 350px;
      padding: toRem(10) toRem(64) toRem(60) toRem(38);
    }
  }

  &-header {
    .app-logo {
      svg {
        width: 105px;
      }
    }
  }

  &-body {
    padding: 0 20px;
    .ellipse-78 {
      width: 88.5px;
      height: 88.5px;
      border-radius: 50%;
      border: solid 8px #f3d56d;
      background-color: #fff;
    }
  }
}
</style>
